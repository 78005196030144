<template>
  <div>
    Authentication successful!
  </div>
</template>

<script>
import ApiGoogleapis from "@/services/googleapis";

export default {
  name: "OAuthHandler",

  created() {
    ApiGoogleapis.saveTokens({
      code: this.$route.query.code,
      state: this.$route.query.state
    }).then(() => {
      window.close();
    });
  }
};
</script>
